import { createContext, FC, useState } from "react";
import { ICourse, ISearchContext, ISelectPageProviderProps } from "./type";

const defaultContext: ISearchContext = {
  coursesContextValue: [],
  setCoursesContextValue: () => {},
  chosenCourseContextValue: {
    country: "Default country",
    language: "Default language",
    name: "Default course",
    startDate: "01.08.2028",
    type: "Default type",
  },
  setChosenCourseContextValue: () => {},
};

export const SearchContext = createContext<ISearchContext>(defaultContext);

export const SearchContextProvider: FC<ISelectPageProviderProps> = ({ children }) => {
  const [coursesContextValue, setCoursesContextValue] = useState<ICourse[]>([]);
  const [chosenCourseContextValue, setChosenCourseContextValue] = useState<ICourse>(
    defaultContext.chosenCourseContextValue,
  );

  return (
    <SearchContext.Provider
      value={{
        coursesContextValue,
        setCoursesContextValue,
        chosenCourseContextValue,
        setChosenCourseContextValue,
      }}
    >
      {children}
    </SearchContext.Provider>
  );
};
