import { ButtonHTMLAttributes, FC } from "react";

const colorScheme = {
  primary: `bg-[#feda00] hover:bg-[#FEC600]`,
  secondary: "border-2 border-[#feda00] hover:bg-[#FEC600]",
  disabled: "bg-[#EFEFF0] text-[#888D92]",
};

const buttonSizes = {
  sm: "w-[100px]",
  md: "w-[180px]",
  lg: "w-[380px]",
};

interface IMainButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  text: string;
  onClick?: () => void;
  color?: "primary" | "secondary";
  className?: string;
  size?: "sm" | "md" | "lg";
  disabled?: boolean;
}

export const MainButton: FC<IMainButtonProps> = ({
  text,
  color = "primary",
  size = "md",
  className = "",
  disabled = false,
  ...rest
}) => {
  const defaultClasses = "flex items-center justify-center font-medium text-sm";
  const cssClass = `h-[42px] ${buttonSizes[size]} ${
    disabled ? colorScheme.disabled : colorScheme[color]
  } ${className} ${defaultClasses}`;

  return (
    <button className={cssClass} {...rest}>
      {text}
    </button>
  );
};
