import { useLocation } from "react-router-dom";
import { ROUTES_ENUM } from "../../../router/type";
import { CustomLink } from "../../CustomLink/CustomLink";
import { BackArrowIcon } from "./BackArrowIcon";
import { BackButton } from "../../BackButton/BackButton";

export const Nav = () => {
  const { pathname } = useLocation();

  const isHomePage = pathname === ROUTES_ENUM.HOME;
  const isAtionsPage = pathname === ROUTES_ENUM.ACTIONS;
  const isSearchResultPage = pathname === ROUTES_ENUM.SEARCH_RESULTS;

  const isLinkForBackStep = !isHomePage && (isAtionsPage || isSearchResultPage);
  const isLinkForProfilePage = !isHomePage && !isLinkForBackStep;

  return (
    <nav className='flex items-center h-[70px]'>
      {isLinkForBackStep && <BackButton />}
      {isLinkForProfilePage && (
        <CustomLink
          data-test-id='NavLinkToHome' // Unique identifier for test instead of "id" property
          text='Back to Profile'
          to={ROUTES_ENUM.HOME}
          className='text-white border-b border-yellow-400 hover:text-yellow-400'
          icon={<BackArrowIcon />}
        />
      )}
    </nav>
  );
};
