import classNames from "classnames";

type Props = React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
> & {
  type: string;
  text: string;
  classArray: classNames.ArgumentArray;
};
export const Button = (props: Props) => {
  const { text, type, onClick, disabled, classArray } = props;

  return (
    <button type={type} className={classNames(classArray)} onClick={onClick} disabled={disabled}>
      {text}
    </button>
  );
};
