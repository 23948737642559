import { FC, useCallback, useEffect, useState } from "react";
import { ICustomIFrameProps } from "./type";
import { createPortal } from "react-dom";

export const CustomIFrame: FC<ICustomIFrameProps> = ({ title, children, ...props }) => {
  const [iframeRef, setIframeRef] = useState<HTMLIFrameElement | null>(null);
  const mountNode = iframeRef?.contentWindow?.document?.body;

  const adjustIframeHeight = useCallback(() => {
    if (iframeRef && iframeRef.contentWindow && iframeRef.contentWindow.document.body) {
      iframeRef.style.height = `${iframeRef.contentWindow.document.body.scrollHeight}px`;
    }
  }, [iframeRef]);

  useEffect(() => {
    if (iframeRef) {
      const iframeDocument = iframeRef.contentWindow?.document;
      if (iframeDocument) {
        // Copy stylesheets from the main document to the iframe document
        Array.from(document.styleSheets).forEach((styleSheet) => {
          try {
            if (styleSheet.href) {
              // If the stylesheet is linked (has a href), create a link element
              const newLinkElement = iframeDocument.createElement("link");
              newLinkElement.rel = "stylesheet";
              newLinkElement.href = styleSheet.href;
              iframeDocument.head.appendChild(newLinkElement);
            } else if (styleSheet.cssRules) {
              // If the stylesheet is embedded (has cssRules), create a style element
              const newStyleElement = iframeDocument.createElement("style");
              Array.from(styleSheet.cssRules).forEach((cssRule) => {
                newStyleElement.appendChild(iframeDocument.createTextNode(cssRule.cssText));
              });
              iframeDocument.head.appendChild(newStyleElement);
            }
          } catch (e) {
            console.error("Could not copy stylesheet:", e);
          }
        });

        // Adjust iframe height on load
        const onLoadHandler = () => {
          adjustIframeHeight();

          // Use MutationObserver to track changes in iframe content and adjust height accordingly
          const observer = new MutationObserver(adjustIframeHeight);
          observer.observe(iframeDocument.body, {
            childList: true,
            subtree: true,
            characterData: true,
          });

          // Clean up observer when the iframe is unmounted
          return () => observer.disconnect();
        };

        iframeRef.onload = onLoadHandler;

        // Initial height adjustment after iframe is mounted
        adjustIframeHeight();

        // Add resize event listener to the iframe's window
        iframeDocument.defaultView?.addEventListener("resize", adjustIframeHeight);

        // Add resize event listener to the iframe's window
        const resizeObserver = new ResizeObserver(adjustIframeHeight);
        resizeObserver.observe(iframeDocument.body);

        return () => {
          iframeDocument.defaultView?.removeEventListener("resize", adjustIframeHeight);
          resizeObserver.disconnect();
        };
      }
    }
  }, [iframeRef, adjustIframeHeight]);

  return (
    <iframe ref={setIframeRef} title={title} {...props}>
      {mountNode && createPortal(children, mountNode)}
    </iframe>
  );
};
