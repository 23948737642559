import { MouseEventHandler, useContext, useRef, useState } from "react";
import { PracticeContainer } from "../../components/PracticeContainer/PracticeContainer";
import { Typography } from "../../components/Typography/Typography";
import { SearchContext } from "../../context/searchContext";
import { CustomIFrame } from "../../components/CustomIFrame/CustomIFrame";
import { MainButton } from "../../components/MainButton/MainButton";
import { useNavigate } from "react-router-dom";
import { ROUTES_ENUM } from "../../router/type";
import { ActionsButton } from "../../components/ActionsButton/ActionsButton";
import { ApprovementSVG } from "../../components/ApprovementSVG/ApprovementSVG";

export const ActionsPage = () => {
  // Description of course maybe added later
  const { chosenCourseContextValue } = useContext(SearchContext);

  const [actionResultMessage, setActionResultMessage] = useState<string>("");

  const navigate = useNavigate();

  const iFrameContentRef = useRef<HTMLDivElement>(null);

  const onConfirm: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();
    if (actionResultMessage) setActionResultMessage("");

    window.alert("You have called alert!");

    setActionResultMessage("Congratulations, you have successfully enrolled in the course!");
  };

  const onGetDiscount: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();
    if (actionResultMessage) setActionResultMessage("");

    const confirmation = window.confirm("Are you sure you want to apply the discount?");

    if (confirmation) setActionResultMessage("You received a 10% discount on the second course.");
  };

  const onCancelCourse: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();
    if (actionResultMessage) setActionResultMessage("");

    const reason = window.prompt(
      "Here you may describe a reason why you are cancelling your registration (or leave this field empty).",
    );

    setActionResultMessage(
      "Your course application has been cancelled. Reason: " +
        (!!reason ? reason : "You did not notice any reason."),
    );
  };

  return (
    <section>
      <Typography renderAs='h1' className='mb-[35px]'>
        Your application has been accepted!
      </Typography>
      <PracticeContainer>
        <CustomIFrame className='w-full' title='Finish your registration'>
          <div ref={iFrameContentRef} className='flex flex-col gap-10'>
            <Typography renderAs='h2'>
              Click one of the buttons to complete your registration on{" "}
              <u>{chosenCourseContextValue.name}</u>
            </Typography>
            <div className='flex flex-col gap-6'>
              <div className='flex flex-col gap-6'>
                <ActionsButton
                  id='AlertButton' // Unique identifier for tests
                  onClick={onConfirm}
                  text='Confirm'
                  tooltip='Click the button to open a JavaScript alert'
                />
                <ActionsButton
                  className='ConfirmButton' // Unique identifier for tests instead of "id" property
                  onDoubleClick={onGetDiscount}
                  text='Get Discount'
                  tooltip='Double click the button to open a JavaScript confirm'
                />
                <ActionsButton
                  data-test-id='PromptButton' // Unique identifier for tests instead of "id" property
                  onContextMenu={onCancelCourse}
                  text='Cancel course'
                  tooltip='Right click to open a JavaScript prompt'
                />
              </div>
              <hr />
              <div
                className={`flex p-6 bg-[#FAFAFA] text-[${
                  actionResultMessage ? "#000000" : "#888D92"
                }]`}
              >
                <span>Results: </span>
                {actionResultMessage && (
                  <span className='font-light flex'>
                    <ApprovementSVG width={24} height={12} />
                    {actionResultMessage}
                  </span>
                )}
              </div>
            </div>
            <MainButton
              disabled={!actionResultMessage}
              className='self-end ActionsPageFinishButton'
              text='Finish'
              onClick={() => navigate(ROUTES_ENUM.HOME)}
            />
          </div>
        </CustomIFrame>
      </PracticeContainer>
    </section>
  );
};
