import React from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import _ from "lodash";
import { format } from "date-fns";
import { Button } from "../Button";
import { Input } from "../Input";
import { DatePicker } from "../DatePicker";

interface RegistrationFormValues {
  firstName: string;
  lastName: string;
  email: string;
  dateOfBirth: string;
  password: string;
  passwordConfirmation: string;
}

type Props = {
  validationSchema: Yup.ObjectSchema<RegistrationFormValues>;
  onSubmit: (values: RegistrationFormValues) => Promise<any>;
  initialValues: RegistrationFormValues;
  disabledFields?: Partial<Record<keyof RegistrationFormValues, boolean>>;
  fieldsNames: Record<keyof RegistrationFormValues, string>;
  buttonText: string;
  footer?: string | JSX.Element | JSX.Element[];
};

export function AccountForm(props: Props) {
  const {
    onSubmit,
    buttonText,
    footer,
    initialValues,
    validationSchema,
    disabledFields,
    fieldsNames,
  } = props;

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => {
        onSubmit({
          ...values,
          dateOfBirth:
            typeof values.dateOfBirth === "string"
              ? values.dateOfBirth
              : format(values.dateOfBirth as number, "dd/MM/yyyy"),
        }).finally(() => {
          setSubmitting(false);
        });
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        /* and other goodies */
      }) => (
        <form onSubmit={handleSubmit} className=' mt-8'>
          <div className='flex flex-col gap-4'>
            <Input
              type='text'
              label={fieldsNames.firstName}
              name={"firstName"}
              placeholder='First Name'
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.firstName}
              error={(touched.firstName && errors.firstName) || undefined}
            />
            <Input
              type='text'
              label={fieldsNames.lastName}
              name={"lastName"}
              placeholder='Last Name'
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.lastName}
              error={(touched.lastName && errors.lastName) || undefined}
            />
            <DatePicker
              label={fieldsNames.dateOfBirth}
              name='dateOfBirth'
              placeholder='Date of birth'
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.dateOfBirth}
              error={(touched.dateOfBirth && errors.dateOfBirth) || undefined}
            />
            <Input
              type='text'
              name='email'
              label={fieldsNames.email}
              placeholder='Enter email'
              disabled={disabledFields?.email}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
              error={(touched.email && errors.email) || undefined}
            />
            <Input
              type='password'
              name='password'
              label={fieldsNames.password}
              placeholder='Enter password'
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.password}
              error={(touched.password && errors.password) || undefined}
            />
            <Input
              type='password'
              name='passwordConfirmation'
              label={fieldsNames.passwordConfirmation}
              placeholder='Confirm Password'
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.passwordConfirmation}
              error={(touched.passwordConfirmation && errors.passwordConfirmation) || undefined}
            />
          </div>

          <Button
            disabled={isSubmitting || !_.isEmpty(errors)}
            text={buttonText}
            type='submit'
            id='AccountFormSubmitButton' // Unique identifier for tests
            classArray={[
              "mt-7 h-10 bg-[#feda00] rounded-3xl w-full",
              (isSubmitting || !_.isEmpty(errors)) && "opacity-60",
            ]}
          />

          {footer}
        </form>
      )}
    </Formik>
  );
}
