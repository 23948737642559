import { useNavigate } from "react-router-dom";
import logo from "../../assets/logo.svg";
import backArrowIcon from "../../assets/back_arrow_icon.svg";

type Props = {
  title: string;
  showBackIcon?: boolean;
  children: string | JSX.Element | JSX.Element[];
};

export function EnterPointLayout(props: Props) {
  const { title, children, showBackIcon } = props;
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };

  return (
    <div className="w-full h-[100vh] bg-[url('./assets/loginBg.png')] bg-center bg-no-repeat bg-cover">
      <header className='flex justify-center items-center pt-[45px]'>
        <img src={logo} alt='logo' />
      </header>

      <div className='mx-auto mt-20 max-w-[495px] bg-white p-5 pt-8 shadow rounded-lg'>
        <div className='text-center relative max-w-[345px] mx-auto'>
          <div className='flex flex-col justify-center'>
            {showBackIcon && (
              <img
                onClick={goBack}
                src={backArrowIcon}
                className='absolute h-[24px] cursor-pointer'
                alt='back button'
              />
            )}
            <h1 className='text-2xl'>{title}</h1>
          </div>
          {children}
        </div>
      </div>
    </div>
  );
}
