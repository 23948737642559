import { useState } from "react";
import { expand } from "../../common/icons";
import { ROUTES_ENUM } from "../../router/type";
import { useNavigate } from "react-router-dom";

interface OptionProps {
  title: string;
  route: ROUTES_ENUM;
}

const Option = ({ title, route }: OptionProps) => {
  const navigate = useNavigate();

  return (
    <div
      className='justify-center px-8 py-4 w-full bg-white hover:bg-custom-gray'
      onClick={() => navigate(route)}
    >
      {title}
    </div>
  );
};

interface SelectPopupProps {
  options: OptionProps[];
}

export const SelectPopup = ({ options }: SelectPopupProps) => {
  return (
    <div className='flex flex-col text-sm text-black bg-white shadow-custom absolute mt-12 cursor-pointer'>
      {options.map((option) => (
        <Option key={option.route} title={option.title} route={option.route} />
      ))}
    </div>
  );
};

interface SelectProps {
  name: string;
  items: { title: string; route: ROUTES_ENUM }[];
}

export const SelectButton = ({ name, items }: SelectProps) => {
  const [popupVisible, setPopupVisible] = useState(false);

  return (
    <div className='flex flex-col items-end' onMouseLeave={() => setPopupVisible(false)}>
      <div
        className='flex gap-1.5 justify-center self-end py-2.5 pr-5 pl-6 text-sm font-medium text-center text-black bg-yellow-400 max-md:pl-5 cursor-pointer'
        onMouseEnter={() => !popupVisible && setPopupVisible(true)}
      >
        <div className='my-auto'>{name}</div>
        <img src={expand} alt='Expand' className='shrink-0 w-6 aspect-square' />
      </div>
      {popupVisible && (
        <SelectPopup
          options={items.map((i) => ({ title: i.title, route: i.route } as OptionProps))}
        />
      )}
    </div>
  );
};
