import { FC, SVGAttributes } from "react";

export const ApprovementSVG: FC<SVGAttributes<SVGSVGElement>> = ({
  width = 24,
  height = 16,
  ...props
}) => (
  <div className='w-6 h-6 mx-2 flex items-center justify-center'>
    <svg
      width={width}
      height={height}
      viewBox='0 0 24 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M23.5839 0.406035C23.0291 -0.135361 22.1296 -0.13536 21.5748 0.406128L9.02929 12.6521L2.42526 6.20579C1.87053 5.6643 0.970947 5.6643 0.41612 6.20579C-0.138707 6.74728 -0.138707 7.62538 0.41612 8.16696L8.02467 15.5939C8.30209 15.8647 8.66569 16 9.02919 16C9.3927 16 9.7564 15.8646 10.0337 15.5939L23.5839 2.36721C24.1387 1.82572 24.1387 0.947615 23.5839 0.406035Z'
        fill='#6AC631'
      />
    </svg>
  </div>
);
