import { createContext, FC, useContext, useEffect, useState } from "react";
import {
  getCountriesForSelect,
  getCoursesForSelect,
  getLanguagesForSelect,
  getTypesForSelect,
} from "../api/securedQueries";
import { ICourse, ISelectPageContext, ISelectPageProviderProps } from "./type";
import { UserContext } from "./userContext";

const defaultContext: ISelectPageContext = {
  countries: [],
  courses: [],
  languages: [],
  types: [],
};

export const SelectPageContext = createContext<ISelectPageContext>(defaultContext);

export const SelectPageProvider: FC<ISelectPageProviderProps> = ({ children }) => {
  const [countries, setCountries] = useState<string[]>([]);
  const [languages, setLanguages] = useState<string[]>([]);
  const [types, setTypes] = useState<string[]>([]);
  const [courses, setCourses] = useState<ICourse[]>([]);

  const userContext = useContext(UserContext);

  useEffect(() => {
    if (userContext?.account) {
      const fetchData = async () => {
        try {
          const [countriesResponse, languagesResponse, typesResponse, startDatesResponse] =
            await Promise.all([
              getCountriesForSelect(),
              getLanguagesForSelect(),
              getTypesForSelect(),
              getCoursesForSelect(),
            ]);

          setCountries(countriesResponse.data.countries);
          setLanguages(languagesResponse.data.languages);
          setTypes(typesResponse.data.types);
          setCourses(startDatesResponse.data.courses);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };

      fetchData();
    }
  }, [userContext]);

  return (
    <SelectPageContext.Provider value={{ countries, courses, languages, types }}>
      {children}
    </SelectPageContext.Provider>
  );
};
