import { useContext, useState } from "react";
import { apply } from "../../common/icons";
import { UserContext } from "../../context/userContext";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { PopupPrompt } from "../../components/PopupPrompt";
import { ProfileView } from "./ProfileView";
import { ProfileEditDialog } from "./ProfileEditDialog";
import { logout, uploadPhoto } from "../../api/securedQueries";
import { ROUTES_ENUM } from "../../router/type";

export const ProfilePage = () => {
  const userContext = useContext(UserContext);
  const navigate = useNavigate();
  const [photoChanged, setPhotoChanged] = useState(false);
  const [showEdit, setShowEdit] = useState(false);

  const logoutMutation = useMutation(logout);
  const uploadPhotoMutation = useMutation(uploadPhoto);

  const practices = [
    { title: "Select", route: ROUTES_ENUM.SELECT },
    { title: "Drag & Drop", route: ROUTES_ENUM.DRAGNDROP },
    { title: "Actions, Alerts & Iframes", route: ROUTES_ENUM.ACTIONS },
  ];

  const onLogout = async () => {
    await logoutMutation.mutateAsync();
    localStorage.removeItem("auth_token");
    userContext?.clearAccount();
    navigate("/login");
  };

  const onUploadPhoto = async (photo: string) => {
    const response = await uploadPhotoMutation.mutateAsync(photo);

    if (response.status >= 200 && response.status < 300) {
      userContext?.updateAccount({
        ...userContext?.account,
        photoBytes: photo,
      });

      setPhotoChanged(!photoChanged);
    }
  };

  return (
    <>
      {photoChanged && (
        <PopupPrompt
          message='Your photo has been updated'
          icon={apply}
          onClose={() => setPhotoChanged(!photoChanged)}
        />
      )}

      <div className='flex flex-col'>
        {showEdit && (
          <ProfileEditDialog
            values={{
              firstName: userContext?.account?.firstName,
              lastName: userContext?.account?.lastName,
              email: userContext?.account?.email,
              dateOfBirth: userContext?.account?.dateOfBirth,
            }}
            onClose={() => setShowEdit(false)}
          />
        )}
        <ProfileView
          account={userContext?.account}
          practices={practices}
          onLogoutClick={onLogout}
          onPhotoChange={onUploadPhoto}
          onEditClick={() => setShowEdit(true)}
        />
      </div>
    </>
  );
};
