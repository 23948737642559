import cn from "classnames";

type Props = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> & {
  label: string;
  name: string;
  placeholder: string;
  type: "text" | "password" | "select";
  error?: string;
  customClassName?: string;
};
export const Input = (props: Props) => {
  const {
    label,
    type,
    name,
    placeholder,
    disabled,
    error,
    onChange,
    onBlur,
    onFocus,
    value,
    customClassName,
  } = props;

  return (
    <div>
      <label className='flex flex-col text-left w-full'>
        <span className='text-xs text-gray-500 rounded-b mb-1'>{label}</span>
        <input
          className={cn([
            customClassName || "border-gray-500 rounded border outline-none px-3.5 h-9 text-sm",
            error && "border-rose-500",
          ])}
          type={type}
          name={name}
          placeholder={placeholder}
          onChange={onChange}
          onBlur={onBlur}
          onFocus={onFocus}
          value={value}
          disabled={disabled}
        />
      </label>
      {error && (
        <div className='text-right relative'>
          <span className='absolute right-0 text-rose-500 text-sm'>{error}</span>
        </div>
      )}
    </div>
  );
};
