import React, { useState } from 'react';
import {
  format,
  addMonths,
  subMonths,
  startOfMonth,
  endOfMonth,
  startOfWeek,
  endOfWeek,
  addDays,
  isSameMonth,
  isSameDay,
  parse,
  isValid,
} from 'date-fns';
import { enUS } from 'date-fns/locale';
import { arrowLeft, arrowRight, calendar_yellow } from '../../common/icons';
import { Input } from '../Input';
import { FormikErrors, FormikHandlers } from 'formik';

interface DatePickerHeaderProps {
  currentDate: Date;
  onRightClick: () => void;
  onLeftClick: () => void;
}

const DatePickerHeader = ({
  currentDate,
  onRightClick,
  onLeftClick,
}: DatePickerHeaderProps) => {
  return (
    <div className="flex justify-between items-center p-4 bg-white">
      <div className="text-lg font-normal text-[20px] text-[#020303]">
        <span>{format(currentDate, 'MMM yyyy')}</span>
      </div>
      <div className="flex space-x-2">
        <div className="cursor-pointer" onClick={onLeftClick}>
          <img src={arrowLeft} alt="arrow_left" />
        </div>
        <div className="cursor-pointer" onClick={onRightClick}>
          <img src={arrowRight} alt="arrow_right" />
        </div>
      </div>
    </div>
  );
};

interface DatePickerDaysProps {
  currentDate: Date;
}

const DatePickerDays = ({ currentDate }: DatePickerDaysProps) => {
  const days = [];
  const startDate = startOfWeek(currentDate, { weekStartsOn: 1 });

  for (let i = 0; i < 7; i++) {
    days.push(
      <div
        className="flex justify-center items-center w-12 h-10 text-[18px] font-normal text-[#828282]"
        key={i}
      >
        {format(addDays(startDate, i), 'EEEEE', { locale: enUS })}
      </div>,
    );
  }

  return (
    <div className="flex bg-white border-b-[1px] border-b-[#e3e3e3]">
      {days}
    </div>
  );
};

interface DatePickerCellProps {
  dateFormat: string;
  currentDate: Date;
  selectedDate: Date | null;
  onSelect: (date: string) => void;
}

const DatePickerCell = ({
  dateFormat,
  currentDate,
  selectedDate,
  onSelect,
}: DatePickerCellProps) => {
  const monthStart = startOfMonth(currentDate);
  const monthEnd = endOfMonth(monthStart);
  const startDate = startOfWeek(monthStart, { weekStartsOn: 1 });
  const endDate = endOfWeek(monthEnd, { weekStartsOn: 1 });

  const rows = [];
  let days = [];
  let day = startDate;
  let formattedDate = '';

  while (day <= endDate) {
    for (let i = 0; i < 7; i++) {
      if (isSameMonth(day, monthStart)) {
        formattedDate = format(day, 'd');
        const cloneDay = day;
        days.push(
          <div
            className={`flex justify-center items-center w-12 h-10 cursor-pointer relative text-[18px] font-normal text-[#020303] ${
              selectedDate && isSameDay(day, selectedDate)
                ? 'border-yellow-300'
                : 'hover:bg-yellow-300'
            }`}
            key={day.toString()}
            onClick={() => onSelect(format(cloneDay, dateFormat))}
          >
            <span className="z-10">{formattedDate}</span>
            {selectedDate && isSameDay(day, selectedDate) && (
              <span className="absolute top-0 left-0 w-full h-full border-2 border-yellow-300 z-0"></span>
            )}
          </div>,
        );
      } else {
        days.push(<div key={day.toString()} className="w-12 h-10" />);
      }
      day = addDays(day, 1);
    }
    rows.push(
      <div className="flex bg-white" key={day.toString()}>
        {days}
      </div>,
    );
    days = [];
  }
  return <div className="p-4 bg-white">{rows}</div>;
};

interface DatePickerProps {
  value?: string;
  format?: string;
  setFieldValue: (field: string, value: string) => void;
  errors: FormikErrors<{ dateOfBirth: string }>;
  onChange: FormikHandlers['handleChange'];
}

export const DatePicker = ({
  value,
  format = 'dd/MM/yyyy',
  onChange,
  setFieldValue,
  errors,
}: DatePickerProps) => {
  const [showCalendar, setShowCalendar] = useState(false);

  const convertToUSDate = (date: string | undefined) => {
    if (date) {
      const parsedDate = parse(date, format, new Date(), { locale: enUS });
      return isValid(parsedDate) ? parsedDate : new Date();
    } else {
      return new Date();
    }
  };

  const [currentDate, setCurrentDate] = useState(convertToUSDate(value));
  const [selectedDate, setSelectedDate] = useState(convertToUSDate(value));

  const onDateSelect = (date: string) => {
    setFieldValue('dateOfBirth', date);
    setShowCalendar(!showCalendar);
  };

  const onOpenCalendar = () => {
    setSelectedDate(convertToUSDate(value));
    setCurrentDate(convertToUSDate(value));
    setShowCalendar(!showCalendar);
  };

  return (
    <>
      <div>
        <Input
          type="text"
          label="Date of Birth"
          name={'dateOfBirth'}
          placeholder={format}
          value={value}
          onChange={onChange}
          onFocus={() => setShowCalendar(!showCalendar)}
          error={errors.dateOfBirth}
          customClassName="py-2 pr-10 border-gray-500 border-b outline-none h-9 text-sm"
        />
        <img
          src={calendar_yellow}
          alt="icon"
          className="absolute right-2 top-2/3 transform -translate-y-1/2 w-6 h-6 cursor-pointer"
          onClick={onOpenCalendar}
        ></img>
      </div>

      {showCalendar && (
        <div
          className="fixed inline-block border border-gray-300 shadow-lg rounded-md bg-white max-w-[420px] mx-auto p-4 mt-14"
          onBlur={() => setShowCalendar(!showCalendar)}
        >
          <DatePickerHeader
            currentDate={currentDate}
            onRightClick={() => setCurrentDate(addMonths(currentDate, 1))}
            onLeftClick={() => setCurrentDate(subMonths(currentDate, 1))}
          />
          <div className="flex flex-col items-center">
            <DatePickerDays currentDate={currentDate} />
            <DatePickerCell
              dateFormat={format}
              currentDate={currentDate}
              selectedDate={selectedDate}
              onSelect={(date) => onDateSelect(date)}
            />
          </div>
        </div>
      )}
    </>
  );
};
