import { FC } from "react";
import { IActionsButtonProps } from "./type";
import { TooltipSVG } from "../TooltipSVG/TooltipSVG";

export const ActionsButton: FC<IActionsButtonProps> = ({ text, tooltip, ...rest }) => (
  <div className='flex gap-4 items-center'>
    <button
      {...rest}
      className='w-[176px] px-[15px] py-[7px] bg-sky-100 rounded hover:bg-sky-300 active:bg-sky-200'
    >
      {text}
    </button>
    <TooltipSVG text={tooltip} />
  </div>
);
