import axios from "axios";
import { AccountRegistration } from "../types/Account";
import { ISelectPageSearchRequest } from "./type";

const securedBaseURL = `${process.env.REACT_APP_API_URL}/api/secured`;

const axiosInstance = axios.create({
  baseURL: securedBaseURL,
});

axiosInstance.interceptors.request.use(function (config) {
  const token = localStorage.getItem("auth_token");
  config.headers.Authorization = token ? `Bearer ${token}` : "";
  return config;
});

const logout = () => {
  return axiosInstance.post("/logout");
};

const editAccount = (values: Partial<AccountRegistration>) => {
  return axiosInstance.patch("/account/edit", values);
};

const deleteAccount = () => {
  return axiosInstance.delete("/account/delete");
};

const getAccount = () => {
  return axiosInstance.get("/account/view");
};

const uploadPhoto = (photoBase64: string) => {
  return axiosInstance.post(`/account/photo`, { photoBytes: photoBase64 });
};

const getCountriesForSelect = () => axiosInstance.get("/course/countries");
const getLanguagesForSelect = () => axiosInstance.get("/course/languages");
const getTypesForSelect = () => axiosInstance.get("/course/types");
const getCoursesForSelect = () => axiosInstance.get("/course");

const getFilteredCourses = (searchParams: ISelectPageSearchRequest) =>
  axiosInstance.post("/course/filter", searchParams);

export {
  logout,
  editAccount,
  deleteAccount,
  getAccount,
  axiosInstance,
  securedBaseURL,
  uploadPhoto,
  getCountriesForSelect,
  getLanguagesForSelect,
  getTypesForSelect,
  getCoursesForSelect,
  getFilteredCourses,
};
