import { useContext } from "react";
import * as Yup from "yup";
import { useMutation } from "react-query";
import { AccountForm } from "../../components/AccountForm";
import { registration } from "../../api/publicQueries";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../context/userContext";
import { EnterPointLayout } from "../../components/Layout/EnterPointLayout";
import { CustomLink } from "../../components/CustomLink/CustomLink";

export interface RegistrationFormValues {
  firstName: string;
  lastName: string;
  email: string;
  dateOfBirth: string;
  password: string;
  passwordConfirmation: string;
}

const RegistrationSchema = Yup.object().shape({
  firstName: Yup.string().min(1, "Minimum 1 character").required("Required"),
  lastName: Yup.string().min(1, "Minimum 1 character").required("Required"),
  email: Yup.string().email("Invalid email address").required("Required"),
  dateOfBirth: Yup.string().required("Required"),
  password: Yup.string()
    .min(8, "Minimum 8 characters")
    .max(20, "Maximum 20 characters")
    // .matches(/[a-zA-Z0-9]/, 'Password can only contain Latin letters and numbers.')
    .required("Required"),
  passwordConfirmation: Yup.string()
    .oneOf([Yup.ref("password")], "Passwords must match")
    .required("Required"),
});

const fieldsNames = {
  firstName: "First Name",
  lastName: "Last Name",
  email: "Email",
  dateOfBirth: "Date of birth",
  password: "Password",
  passwordConfirmation: "Confirm password",
};

export function RegistrationPage() {
  const mutation = useMutation(registration);
  const userContext = useContext(UserContext);
  const navigate = useNavigate();

  const initialValues: RegistrationFormValues = {
    firstName: "",
    lastName: "",
    email: "",
    dateOfBirth: "",
    password: "",
    passwordConfirmation: "",
  };

  const onSubmit = async (values: RegistrationFormValues) => {
    const response = await mutation.mutateAsync({
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      dateOfBirth: values.dateOfBirth,
      password: values.password,
    });

    localStorage.setItem("auth_token", response.data["jwt-token"]);
    userContext?.getAccount();
    navigate("/");
  };

  return (
    <EnterPointLayout title='Registration'>
      <AccountForm
        validationSchema={RegistrationSchema}
        fieldsNames={fieldsNames}
        onSubmit={onSubmit}
        initialValues={initialValues}
        buttonText='Submit'
        footer={
          <CustomLink
            data-registration-link='RegistrationPageLink' // Unique identifier for test instead of "id" property
            to='/'
            text='Sing in'
            className='mt-3 block cursor-pointer hover:underline'
          />
        }
      />
    </EnterPointLayout>
  );
}
