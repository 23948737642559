import { ReactElement } from "react";

export interface IProtectedRouteProps {
  page: ReactElement;
}

export enum ROUTES_ENUM {
  HOME = "/",
  LOGIN = "/login",
  REGISTRATION = "/registration",
  ACTIONS = "/actions",
  DRAGNDROP = "/dragndrop",
  SELECT = "/select",
  SEARCH_RESULTS = "/search_results",
}
