import { edit, signOut } from "../../common/icons";
import { PhotoWithUpload } from "../../components/PhotoWithUpload";
import { SelectButton } from "../../components/SelectButton";
import { Typography } from "../../components/Typography/Typography";
import { ROUTES_ENUM } from "../../router/type";
import { Account } from "../../types/Account";

interface ProfileProps {
  account: Account | undefined;
  practices: { title: string; route: ROUTES_ENUM }[];
  onLogoutClick: () => void;
  onPhotoChange: (photo: string) => void;
  onEditClick: () => void;
}

export const ProfileView = ({
  account,
  practices,
  onLogoutClick,
  onPhotoChange,
  onEditClick,
}: ProfileProps) => (
  <section className='flex justify-center items-center self-center w-full'>
    <div className='flex flex-col w-full max-w-[1110px] max-md:max-w-full'>
      <div className='flex gap-5 mb-[35px] justify-between w-full max-md:flex-wrap max-md:max-w-full'>
        <PhotoWithUpload photoBytes={account?.photoBytes} onPhotoChanged={onPhotoChange} />
        <div className='flex gap-4 self-end pt-7 max-md:flex-wrap flex-1'>
          <Typography renderAs='h1'>
            {account?.firstName} {account?.lastName}
          </Typography>
        </div>
        <img
          src={edit}
          alt='Edit'
          className='shrink-0 mt-16 w-8 aspect-square max-md:mt-10 cursor-pointer'
          onClick={onEditClick}
        />
      </div>
      <hr className='shrink-0 h-px bg-zinc-300 max-md:max-w-full' />
      <div className='flex gap-5 justify-between mt-9 w-full max-md:flex-wrap max-md:max-w-full relative'>
        <div className='max-md:max-w-full'>
          <div className='flex gap-5 max-md:flex-col max-md:gap-0'>
            <div className='flex flex-col max-md:ml-0 max-md:w-full'>
              <div className='flex flex-col grow text-sm font-light leading-5 text-neutral-400 max-md:mt-10'>
                <div>Position</div>
                <div className='mt-1.5 text-base leading-6 text-black'>QA Engineer</div>
                <div className='mt-6'>Technologies</div>
                <div className='justify-center px-2.5 py-1.5 mt-1.5 text-black bg-yellow-400 rounded'>
                  QA Manual
                </div>
              </div>
            </div>
            <div className='flex flex-col ml-5 max-md:ml-0 max-md:w-full'>
              <div className='flex flex-col grow text-sm font-light leading-5 text-neutral-400 max-md:mt-10'>
                <div>E-mail</div>
                <div className='mt-1.5 text-base leading-6 text-black'>{account?.email}</div>
                <div className='mt-6 max-md:mr-1.5'>Date of birth</div>
                <div className='mt-1.5 text-base leading-6 text-black max-md:mr-1.5'>
                  {account?.dateOfBirth}
                </div>
              </div>
            </div>
          </div>
        </div>
        <SelectButton name='AQA Practice' items={practices} />
      </div>
      <div className='flex gap-1.5 self-start mt-12 text-sm text-black max-md:mt-10 cursor-pointer'>
        <img src={signOut} alt='Sign Out' className='shrink-0 w-6 aspect-square' />
        <div className='my-auto' onClick={onLogoutClick}>
          Sign Out
        </div>
      </div>
    </div>
  </section>
);
