import { FC } from "react";
import { IDropTargetProps } from "./type";

export const DropTarget: FC<IDropTargetProps> = ({ className, targetForElementId, ...props }) => (
  <div
    className={`flex flex-col flex-1 items-center justify-center min-h-[200px] border border-dashed border-zinc-200 ${
      className ?? ""
    }`}
    id={`target-${targetForElementId}`} // Unique identifier for tests
    {...props}
  ></div>
);
