import { ContentContainer } from "../../ContentContainer/ContentContainer";
import { ContactInfo } from "./ContactInfo/ContactInfo";
import { Copyright } from "./Copyright/Copyright";

export const Footer = () => {
  return (
    <footer className='bg-slate-950 flex flex-col pt-[70px]'>
      <ContentContainer>
        <ContactInfo />
      </ContentContainer>
      <div className='bg-black'>
        <ContentContainer>
          <Copyright />
        </ContentContainer>
      </div>
    </footer>
  );
};
