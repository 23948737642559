import { useNavigate } from "react-router-dom";
import { BackArrowIcon } from "../Layout/Nav/BackArrowIcon";

export const BackButton = () => {
  const navigate = useNavigate();

  return (
    <div
      data-for-tests----seems-like-cool-attribute-name='NavButtonToBack' // Unique identifier for test instead of "id" property
      className='flex flex-row gap-[10px] items-center cursor-pointer text-white hover:text-yellow-400'
      onClick={() => navigate(-1)}
    >
      <BackArrowIcon />
      <span className='border-b border-yellow-400'>Back</span>
    </div>
  );
};
