import { FC, ReactNode } from "react";
import { Link } from "react-router-dom";

interface ICustomLinkProps {
  text: string;
  to: string;
  className?: string;
  icon?: ReactNode;
}

export const CustomLink: FC<ICustomLinkProps> = ({ text, className, icon, to, ...rest }) => (
  <Link {...rest} to={to} className='flex flex-row gap-[10px] items-center'>
    {icon && icon}
    <span className={className || ""}>{text}</span>
  </Link>
);
