import { FC, useEffect } from "react";
import { IInputDatesProps } from "./type";
import { addOneDayToISODate } from "../../utils/addOneDayToISODate";

const defaults = `h-9 text-sm px-0 flex-1 ring-0`;
const border = `border-[#ADADAD] border-b border-t-0 border-t-0 border-l-0 border-r-0`;
const focus = `focus:border-[#FEDA00] ring-0`;
const active = `active:border-[#FEDA00] ring-0`;
const inputClassName = `${defaults} ${border} ${focus} ${active} relative cursor-pointer focus-visible:ring-0`;

const today = new Date().toISOString().split("T")[0];

export const InputDates: FC<IInputDatesProps> = ({
  startDate,
  endDate,
  setEndDate,
  setStartDate,
}) => {
  useEffect(() => {
    if (startDate) {
      if (endDate === "") setEndDate(addOneDayToISODate(startDate));
      if (Date.parse(startDate) >= Date.parse(endDate)) setEndDate(addOneDayToISODate(startDate));
    }
  }, [startDate, endDate, setEndDate]);

  return (
    <div className='flex gap-4'>
      <input
        data-calendar='1' // Unique identifier for test instead of "id" property
        className={inputClassName}
        title='Start date'
        type='date'
        min={today}
        value={startDate}
        onChange={(e) => {
          setStartDate(e.target.value);
        }}
      />
      <input
        data-calendar='2' // Unique identifier for test instead of "id" property
        className={inputClassName}
        title='End date'
        type='date'
        min={addOneDayToISODate(startDate)}
        value={endDate}
        onChange={(e) => {
          setEndDate(e.target.value);
        }}
      />
    </div>
  );
};
