/**
 * formatDateWithMonthName receives one of the following date formats and returns a standardized format: (Day number) (Month name) (Year as number)
 * @param { Date } dateObj
 * @returns { string } - Date string in the format 'DD Mmmm YYYY' (e.g., '01 March 2023').
 */

export const formatDateWithMonthName = (dateObj: Date): string =>
  new Intl.DateTimeFormat("en-GB", {
    day: "numeric",
    month: "long",
    year: "numeric",
  }).format(dateObj);
