import { FC, useState } from "react";
import { ITooltipSVGProps } from "./type";

export const TooltipSVG: FC<ITooltipSVGProps> = ({ text = "Tooltip" }) => {
  const [color, setColor] = useState("#888D92");
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);

  const onHover = () => {
    setColor("#FEDA00");
    setIsTooltipVisible(true);
  };

  const onHoverLeave = () => {
    setColor("#888D92");
    setIsTooltipVisible(false);
  };

  return (
    <div className='relative z-[1]'>
      {isTooltipVisible && (
        <div className='absolute left-[150%] -top-2 z-0 px-2 py-1 rounded-[4px] shadow-custom min-w-[160px] text-xs'>
          {text}
        </div>
      )}
      <svg
        width='24'
        height='24'
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        className='cursor-pointer select-none z-[2]'
        onMouseEnter={onHover}
        onMouseLeave={onHoverLeave}
      >
        <g opacity='0.5'>
          <path
            d='M12 3C7.03953 3 3 7.03953 3 12C3 16.9605 7.03953 21 12 21C16.9605 21 21 16.9605 21 12C21 7.03953 16.9605 3 12 3ZM12 19.7442C7.73023 19.7442 4.25581 16.2698 4.25581 12C4.25581 7.73023 7.73023 4.25581 12 4.25581C16.2698 4.25581 19.7442 7.73023 19.7442 12C19.7442 16.2698 16.2698 19.7442 12 19.7442Z'
            fill={color}
          />
          <path
            d='M11.999 10.7998C11.6432 10.7998 11.3711 11.0719 11.3711 11.4277V16.2417C11.3711 16.5975 11.6432 16.8696 11.999 16.8696C12.3548 16.8696 12.6269 16.5975 12.6269 16.2417V11.4277C12.6269 11.0719 12.3548 10.7998 11.999 10.7998Z'
            fill={color}
          />
          <path
            d='M11.9993 7.7998C11.5389 7.7998 11.1621 8.17655 11.1621 8.63701C11.1621 9.09748 11.5389 9.47422 11.9993 9.47422C12.4598 9.47422 12.8365 9.09748 12.8365 8.63701C12.8365 8.17655 12.4598 7.7998 11.9993 7.7998Z'
            fill={color}
          />
        </g>
      </svg>
    </div>
  );
};
