import axios from 'axios';
import { AccountRegistration } from '../types/Account';

const publicBaseURL = `${process.env.REACT_APP_API_URL}/api/public`;

const axiosInstance = axios.create({
  baseURL: publicBaseURL,
});

const login = ({ email, password }: { email: string; password: string }) => {
  return axiosInstance.post('/login', {
    email,
    password,
  });
};

const registration = (values: AccountRegistration) => {
  return axiosInstance.post('/registration', values);
};

export { axiosInstance, login, registration, publicBaseURL };
