import * as React from 'react';
import { close } from '../../common/icons';

interface PopupPromptProps {
  message: string;
  icon: string;
  onClose: () => void;
}

export const PopupPrompt = ({ message, icon, onClose }: PopupPromptProps) => {
  return (
    <div className="fixed inset-0 flex justify-center items-center bg-[rgba(2,13,28,0.7)] z-50">
      <section className="relative flex justify-center items-center px-16 py-16 text-2xl font-light leading-8 text-center text-black bg-white max-w-[480px] mb-[calc(100vh/3)]">
        <div className="flex flex-col max-w-full w-[274px]">
          <img
            alt=""
            src={icon}
            className="self-center aspect-square fill-yellow-400 w-[60px]"
          />
          <img
            alt="Close"
            src={close}
            onClick={onClose}
            className="absolute top-6 right-6 mt-[-8px] mr-[-8px] cursor-pointer w-5 h-5"
          />
          <p className="mt-12">{message}</p>
        </div>
      </section>
    </div>
  );
};
