import { useContext } from "react";
import { logoCycle, noPhoto } from "../../../common/icons";
import { UserContext } from "../../../context/userContext";

export const Header = () => {
  const userContext = useContext(UserContext);

  return (
    <header className='bg-white my-[19px]'>
      <div className='flex justify-between'>
        <div className='flex gap-3 my-auto'>
          <img src={logoCycle} alt='Logo' className='shrink-0 w-10 aspect-[1.05]' />
          <div className='my-auto font-extrabold'>ANDERSEN</div>
        </div>
        <img
          src={userContext?.account?.photoBytes || noPhoto}
          alt='PhotoUrl'
          className='shrink-0 self-start aspect-square w-[44px] rounded-full'
        />
      </div>
    </header>
  );
};
