import { createContext, FC, useEffect, useState } from "react";
import { Account } from "../types/Account";
import { getAccount } from "../api/securedQueries";

import { useMutation } from "react-query";

export const UserContext = createContext<{
  account?: Account;
  getAccount: () => Promise<void>;
  clearAccount: () => void;
  updateAccount: (values: Partial<Account>) => void;
} | null>(null);

export const UserProvider: FC<{ children: React.ReactNode }> = ({ children }) => {
  const [account, setAccount] = useState<Account | undefined>(undefined);

  const mutation = useMutation(getAccount);

  const getAccountCallback = () => {
    return mutation
      .mutateAsync()
      .then((data) => {
        setAccount(data.data);
      })
      .catch((e) => console.error(e));
  };

  useEffect(() => {
    getAccountCallback();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const clearAccount = () => {
    setAccount(undefined);
  };

  const updateAccount = (values: Partial<Account>) => {
    setAccount((prevAccount) => {
      if (!prevAccount) return { ...values } as Account;
      return { ...prevAccount, ...values };
    });
  };

  return (
    <UserContext.Provider
      value={{
        account,
        clearAccount,
        getAccount: getAccountCallback,
        updateAccount,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
