import { FC } from "react";
import { ITypographyProps } from "./type";

const textSizes = {
  h1: "text-[56px] leading-[65.63px] font-thin",
  h2: "text-[24px] leading-[28.13px]",
  h3: "text-[18px] leading-[28.8px]",
  p: "text-[16px] leading-[18.75px]",
};

export const Typography: FC<ITypographyProps> = ({ children, renderAs = "p", className = "" }) => {
  const cssClass = `${className} ${textSizes[renderAs]}`;

  if (renderAs === "h1") return <h1 className={cssClass}>{children}</h1>;
  if (renderAs === "h2") return <h2 className={cssClass}>{children}</h2>;
  if (renderAs === "h3") return <h3 className={cssClass}>{children}</h3>;

  return <p className={cssClass}>{children}</p>;
};
