import { FormEventHandler, useContext, useState } from "react";
import { PracticeContainer } from "../../components/PracticeContainer/PracticeContainer";
import { Typography } from "../../components/Typography/Typography";
import { SelectPageContext } from "../../context/selectContext";
import { MainButton } from "../../components/MainButton/MainButton";
import { getFilteredCourses } from "../../api/securedQueries";
import { NativeSelect } from "../../components/NativeSelect/NativeSelect";
import { InputDates } from "../../components/InputDates/InputDates";
import { ISelectPageSearchRequest, TCountry, TLanguage, TType } from "../../api/type";
import { useNavigate } from "react-router-dom";
import { ROUTES_ENUM } from "../../router/type";
import { SearchContext } from "../../context/searchContext";
import { ICourse } from "../../context/type";

const initialFormValue: "" = "";

export const SelectPage = () => {
  const navigate = useNavigate();

  const { countries, languages, types, courses } = useContext(SelectPageContext);
  const { setCoursesContextValue } = useContext(SearchContext);

  const [selectedCountry, setSelectedCountry] = useState<TCountry | "">(initialFormValue);
  const [selectedLanguage, setSelectedLanguage] = useState<TLanguage | "">(initialFormValue);
  const [selectedType, setSelectedType] = useState<TType | "">(initialFormValue);
  const [selectedStartDate, setSelectedStartDate] = useState<string>(initialFormValue);
  const [selectedEndDate, setSelectedEndDate] = useState<string>(initialFormValue);
  const [notSelectedCourses, setNotSelectedCourses] = useState<ICourse[]>(courses);
  const [selectedCourses, setSelectedCourses] = useState<ICourse[]>([]);

  // TODO: Handle reset rightly (now it sets first values in selects instead of placeholder)
  // const onReset: FormEventHandler<HTMLFormElement> = () => {
  //   setSelectedCountry(initialFormValue);
  //   setSelectedLanguage(initialFormValue);
  //   setSelectedType(initialFormValue);
  //   setSelectedStartDate(initialFormValue);
  //   setEndDate(initialFormValue);
  // };

  const onSubmit: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();

    const searchParams: ISelectPageSearchRequest = {};

    if (selectedCountry) searchParams.country = selectedCountry;
    if (selectedLanguage) searchParams.language = selectedLanguage;
    if (selectedType) searchParams.type = selectedType;

    if (selectedStartDate && selectedEndDate) {
      const prepareDateForBackEnd = (dateString: string) => {
        const [year, month, day] = dateString.split("-");
        return `${day}.${month}.${year}`;
      };

      searchParams.startDateFrom = prepareDateForBackEnd(selectedStartDate);
      searchParams.startDateTo = prepareDateForBackEnd(selectedEndDate);
    }

    getFilteredCourses(searchParams)
      .then((response) => {
        setCoursesContextValue(response.data.courses);
        navigate(ROUTES_ENUM.SEARCH_RESULTS);
      })
      .catch(console.error);
  };

  const onCourseSelect = (course: ICourse) => {
    setNotSelectedCourses((prev) => prev.filter((element) => course !== element));
    setSelectedCourses((prev) => [...prev, course]);
  };

  const onCourseDeselect = (course: ICourse) => {
    setSelectedCourses((prev) => prev.filter((element) => course !== element));
    setNotSelectedCourses((prev) => [...prev, course]);
  };

  const isSearchAllowed = Boolean(
    selectedCountry || selectedLanguage || selectedType || selectedStartDate,
  );

  return (
    <section>
      <Typography renderAs='h1' className='mb-[35px]'>
        Choose your course
      </Typography>
      <PracticeContainer>
        <form className='flex gap-20 flex-col' onSubmit={onSubmit} /* onReset={onReset} */>
          <div className='flex gap-20'>
            <section className='flex flex-col flex-1 gap-10'>
              <Typography renderAs='h2' className='w-full'>
                Define your study preferences
              </Typography>
              <NativeSelect
                data-lol='SelectCountry' // Unique identifier for test instead of "id" property
                value={selectedCountry}
                onChange={(e) => setSelectedCountry(e.target.value as TCountry)}
                options={countries}
                placeholder='Select country'
                title='Select country'
                defaultValue={initialFormValue}
              />
              <NativeSelect
                id='SelectLanguage' // Unique identifier for test instead of "id" property
                value={selectedLanguage}
                onChange={(e) => setSelectedLanguage(e.target.value as TLanguage)}
                options={languages}
                placeholder='Select language'
                title='Select language'
                defaultValue={initialFormValue}
              />
              <NativeSelect
                data-doubtful-but-ok='SelectType' // Unique identifier for test instead of "id" property
                value={selectedType}
                onChange={(e) => setSelectedType(e.target.value as TType)}
                options={types}
                placeholder='Select type'
                title='Select type'
              />
              <InputDates
                data-scheduler='Scheduler' // Unique identifier for test instead of "id" property
                startDate={selectedStartDate}
                endDate={selectedEndDate}
                setStartDate={setSelectedStartDate}
                setEndDate={setSelectedEndDate}
              />
            </section>
            <section
              className='flex flex-col flex-1 gap-4'
              title='Chosen courses will not be considered while searching!'
            >
              <Typography renderAs='h2'>Select courses</Typography>
              <select
                multiple
                className='shadow-md shadow-custom-gray flex-1 bg-gray-50'
                id='MultipleSelect' // Unique identifier for test instead of "id" property
              >
                {notSelectedCourses.map((course, index) => (
                  <option
                    value={course.name}
                    key={course.name + " " + index}
                    id={course.name + " with index " + index} // Unique identifier for test instead of "id" property
                  >
                    {course.name}
                  </option>
                ))}
              </select>
            </section>
          </div>
          <div className='justify-end self-end flex gap-2'>
            {/*             <MainButton
              text='Reset'
              color='secondary'
              className='justify-end self-end'
              type='reset'
              disabled={!isSearchAllowed}
            /> */}
            <MainButton
              name='SelectPageSearchButton' // Unique identifier for test instead of "id" property
              text='Search'
              type='submit' // Unique identifier for test instead of "id" property
              disabled={!isSearchAllowed}
            />
          </div>
        </form>
      </PracticeContainer>
    </section>
  );
};
