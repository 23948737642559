import { FC, useContext, useEffect } from "react";
import { IProtectedRouteProps, ROUTES_ENUM } from "./type";
import { UserContext } from "../context/userContext";
import { useNavigate } from "react-router-dom";
import { Layout } from "../components/Layout/Layout";

export const ProtectedRoute: FC<IProtectedRouteProps> = ({ page }) => {
  const userContext = useContext(UserContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (!userContext?.account) navigate(ROUTES_ENUM.LOGIN);
  }, [userContext, navigate]);

  return <Layout>{page}</Layout>;
};
