import { useContext } from "react";
import { Formik } from "formik";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import _ from "lodash";
import { FormikHelpers } from "formik/dist/types";
import axios from "axios";
import { Input } from "../../components/Input";
import { Button } from "../../components/Button";
import { CustomLink } from "../../components/CustomLink/CustomLink";
import { login } from "../../api/publicQueries";
import { useMutation } from "react-query";
import { UserContext } from "../../context/userContext";
import { EnterPointLayout } from "../../components/Layout/EnterPointLayout";
import { ROUTES_ENUM } from "../../router/type";

interface LoginFormValues {
  email: string;
  password: string;
}

const LoginSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email address").required("Required"),
  password: Yup.string()
    .min(8, "Minimum 8 characters")
    .max(20, "Maximum 20 characters")
    .required("Required"),
});

export function LoginPage() {
  const initialValues: LoginFormValues = { email: "", password: "" };
  const navigate = useNavigate();
  const userContext = useContext(UserContext);

  const mutation = useMutation(login);

  const onSubmit = async (
    values: LoginFormValues,
    { setSubmitting, setErrors }: FormikHelpers<LoginFormValues>,
  ) => {
    try {
      const response = await mutation.mutateAsync(values);
      localStorage.setItem("auth_token", response.data["jwt-token"]);
      await userContext?.getAccount();
      navigate(ROUTES_ENUM.HOME);
    } catch (e) {
      if (axios.isAxiosError(e) && e?.response?.status === 401) {
        setErrors({
          email: "Email or password is not valid",
          password: "Email or password is not valid",
        });
      }
      console.error("login error", e);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <EnterPointLayout title='Sign In'>
      <Formik initialValues={initialValues} validationSchema={LoginSchema} onSubmit={onSubmit}>
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          /* and other goodies */
        }) => (
          <form onSubmit={handleSubmit} className='mx-auto mt-8 max-w-[345px]'>
            <div className='flex flex-col gap-6'>
              <Input
                type='text'
                name='email'
                label='Email'
                placeholder='Enter email'
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
                error={(touched.email && errors.email) || undefined}
              />
              <Input
                type='password'
                name='password'
                label='Password'
                placeholder='Enter password'
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.password}
                error={(touched.password && errors.password) || undefined}
              />
            </div>

            <Button
              disabled={isSubmitting || !_.isEmpty(errors)}
              text='Sign in'
              type='submit'
              id='LoginSubmitButton' // Unique identifier for tests
              classArray={[
                "mt-7 h-10 bg-[#feda00] rounded-3xl w-full",
                (isSubmitting || !_.isEmpty(errors)) && "opacity-60",
              ]}
            />

            <CustomLink
              data-login-link='LoginPageLink' // Unique identifier for tests instead of "id" property
              to='/registration'
              text='Registration'
              className='mt-3 block cursor-pointer hover:underline'
            />
          </form>
        )}
      </Formik>
    </EnterPointLayout>
  );
}
