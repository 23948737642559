import { useEffect, useState } from "react";
import { DraggableElement } from "../../components/DragNDrop/DraggableElement/DraggableElement";
import { DropTarget } from "../../components/DragNDrop/DropTarget/DropTarget";
import { MainButton } from "../../components/MainButton/MainButton";
import { PracticeContainer } from "../../components/PracticeContainer/PracticeContainer";
import { Typography } from "../../components/Typography/Typography";
import { useNavigate } from "react-router-dom";
import { ROUTES_ENUM } from "../../router/type";
import { ApprovementSVG } from "../../components/ApprovementSVG/ApprovementSVG";

const elements = [
  { id: "manual1", text: "Write test cases" },
  { id: "manual2", text: "Testing requirements" },
  { id: "auto1", text: "Write automation scripts" },
  { id: "auto2", text: "Framework set up" },
];

const categories = [
  { name: "Manual Work", ids: ["manual1", "manual2"] },
  { name: "Automation Work", ids: ["auto1", "auto2"] },
];

export const DragNDropPage = () => {
  const navigate = useNavigate();

  const [highlightedTargets, setHighlightedTargets] = useState<{ [key: string]: boolean }>({});
  const [allElementsStatus, setAllElementsStatus] = useState<{ [key: string]: boolean }>({});
  const [showApproval, setShowApproval] = useState(false);

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => event.preventDefault();

  const handleDrop = (event: React.DragEvent<HTMLDivElement>, id: string) => {
    event.preventDefault();
    const data = event.dataTransfer.getData("text/plain");
    if (data === id) {
      const element = document.getElementById(data);
      if (element) event.currentTarget.appendChild(element);
      setAllElementsStatus((prev) => ({ ...prev, [data]: true }));
      setHighlightedTargets((prev) => ({ ...prev, [data]: false }));
    }
  };

  const handleDragStart = (event: React.DragEvent<HTMLSpanElement>, id: string) => {
    setHighlightedTargets((prev) => ({ ...prev, [id]: true }));
    event.dataTransfer.setData("text/plain", id);
  };

  const handleDragEnd = (id: string) => {
    setHighlightedTargets((prev) => ({ ...prev, [id]: false }));
  };

  const isAllElementsSettled = elements.every(({ id }) => allElementsStatus[id]);

  useEffect(() => {
    if (isAllElementsSettled) {
      setShowApproval(true);
    }
  }, [isAllElementsSettled]);

  return (
    <section className='relative'>
      {isAllElementsSettled && (
        <div
          style={{ backgroundColor: "white" }} // Unique identifier for tests instead of "id" property
          className={`text-lg flex absolute -top-10 right-0 px-10 py-8 shadow-custom transition-opacity duration-500 ${
            showApproval ? "opacity-100" : "opacity-0"
          }`}
        >
          <ApprovementSVG width={24} height={18} />
          Congratulations! Let&apos;s test for the best!
        </div>
      )}
      <Typography renderAs='h1' className='mb-[35px]'>
        Sort your responsibilities
      </Typography>
      <PracticeContainer className='flex flex-col gap-5'>
        <Typography renderAs='h2' className='mb-[40px]'>
          Place the blocks into the cells below
        </Typography>
        <div className='flex flex-row gap-5 min-h-[38px]'>
          {elements.map(({ id, text }) => (
            <DraggableElement
              key={id}
              id={id}
              textContent={text}
              onDragStart={(event) => handleDragStart(event, id)}
              onDragEnd={() => handleDragEnd(id)}
            />
          ))}
        </div>
        <div className='flex flex-row gap-10 basis-full'>
          {categories.map(({ name, ids }) => (
            <section key={name} className='flex-1'>
              <Typography renderAs='h3' className='bg-zinc-200 p-[15.5px] flex justify-center'>
                {name}
              </Typography>
              <div className='flex flex-1'>
                {ids.map((id) => (
                  <DropTarget
                    key={id}
                    targetForElementId={id}
                    onDragOver={handleDragOver}
                    onDrop={(event) => handleDrop(event, id)}
                    className={highlightedTargets[id] ? "bg-cyan-50" : ""}
                  />
                ))}
              </div>
            </section>
          ))}
        </div>
        <MainButton
          id='DragNDropPageFinishButton' // Unique identifier for tests
          text='Finish'
          disabled={!isAllElementsSettled}
          className='self-end'
          onClick={() => navigate(ROUTES_ENUM.HOME)}
        />
      </PracticeContainer>
    </section>
  );
};
