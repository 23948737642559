import { FC } from "react";
import { ILayoutProps } from "./type";
import { Header } from "./Header/Header";
import { Footer } from "./Footer/Footer";
import { Nav } from "./Nav/Nav";
import { ContentContainer } from "../ContentContainer/ContentContainer";

export const Layout: FC<ILayoutProps> = ({ children }) => (
  <div className='bg-black flex flex-col min-h-screen'>
    <div className='bg-white'>
      <ContentContainer>
        <Header />
      </ContentContainer>
    </div>
    <div className='bg-slate-950'>
      <ContentContainer>
        <Nav />
        <main className='bg-zinc-50 px-[115px] pt-[100px] pb-[138px]'>{children}</main>
      </ContentContainer>
    </div>
    <Footer />
  </div>
);
