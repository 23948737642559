import ReactDatePicker, { ReactDatePickerProps } from "react-datepicker";
import { getMonth, getYear } from "date-fns";
import cn from "classnames";

import "react-datepicker/dist/react-datepicker.css";
import { useFormikContext } from "formik";

type Props = ReactDatePickerProps & {
  label: string;
  name: string;
  placeholder: string;
  error?: string;
};

export const DatePicker = (props: Props) => {
  const { label, name, placeholder, error, onBlur, value } = props;
  const { setFieldValue } = useFormikContext();

  const years = range(1900, 2100);
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  return (
    <label className='flex flex-col text-left w-full'>
      <span className='text-xs text-gray-500 rounded-b mb-1'>{label}</span>

      <ReactDatePicker
        className={cn([
          "border-gray-500 rounded border outline-none px-3.5 h-9 text-sm",
          error && "border-rose-500",
        ])}
        wrapperClassName='text-left'
        name={name}
        placeholderText={placeholder}
        onBlur={onBlur}
        selected={(value && new Date(value)) || null}
        onChange={(val) => {
          setFieldValue(name, val);
        }}
        renderCustomHeader={({
          date,
          changeYear,
          changeMonth,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
        }) => (
          <div
            style={{
              margin: 10,
              display: "flex",
              justifyContent: "center",
            }}
          >
            <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
              {"<"}
            </button>
            <select value={getYear(date)} onChange={({ target: { value } }) => changeYear(+value)}>
              {years.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>

            <select
              value={months[getMonth(date)]}
              onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}
            >
              {months.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>

            <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
              {">"}
            </button>
          </div>
        )}
      />

      {error && (
        <div className='text-right relative'>
          <span className='absolute right-0 text-rose-500 text-sm'>{error}</span>
        </div>
      )}
    </label>
  );
};

function range(start: number, end: number) {
  const ans: number[] = [];
  for (let i = start; i <= end; i++) {
    ans.push(i);
  }
  return ans;
}
