import { email, logo, phone } from "../../../../common/icons";

export const ContactInfo = () => {
  return (
    <div className='pb-[70px]'>
      <div className='flex gap-5 justify-between mt-8 max-w-full text-lg leading-7 text-white w-[741px] max-md:flex-wrap'>
        <img
          src={logo}
          alt='Company Logo'
          className='shrink-0 self-start mt-5 max-w-full aspect-[3.13] w-[190px]'
        />
        <div className='flex flex-col max-md:max-w-full m-0 p-0'>
          <div className='font-medium uppercase max-md:max-w-full'>Contact us</div>
          <div className='flex gap-5 justify-between mt-7 max-md:flex-wrap'>
            <div className='flex gap-2'>
              <img src={phone} alt='Phone' className='shrink-0 my-auto aspect-square w-[22px]' />
              <div>+49 22 198 253 169</div>
            </div>
            <div className='flex gap-2'>
              <img src={email} alt='Email' className='shrink-0 my-auto aspect-square w-[22px]' />
              <div>vn@andersenlab.com</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
