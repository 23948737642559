import React from 'react';
import { Formik, FormikProps } from 'formik';
import * as Yup from 'yup';

export interface FormValues {
  [key: string]: any;
}

interface Props<T extends FormValues> {
  validationSchema: Yup.ObjectSchema<T>;
  onSubmit: (values: T) => Promise<any>;
  initialValues: T;
  disabledFields?: Partial<Record<keyof T, boolean>>;
  fieldsNames?: Record<keyof T, string>;
  children: (formProps: FormikProps<T>) => React.ReactNode;
}

export function Form<T extends FormValues>(props: Props<T>) {
  const { onSubmit, initialValues, validationSchema, children } = props;

  return (
    <Formik<T>
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => {
        onSubmit({ ...values }).finally(() => {
          setSubmitting(false);
        });
      }}
    >
      {(formikProps) => (
        <form onSubmit={formikProps.handleSubmit}>{children(formikProps)}</form>
      )}
    </Formik>
  );
}
