import { RouterProvider } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { UserProvider } from "./context/userContext";
import { ROUTER } from "./router/router";
import { SelectPageProvider } from "./context/selectContext";
import { SearchContextProvider } from "./context/searchContext";

const queryClient = new QueryClient();

export function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <UserProvider>
        <SelectPageProvider>
          <SearchContextProvider>
            <RouterProvider router={ROUTER} />
          </SearchContextProvider>
        </SelectPageProvider>
      </UserProvider>
    </QueryClientProvider>
  );
}
