import { FC } from "react";
import { INativeSelectProps } from "./type";

const defaults = `h-9 text-sm px-0 flex ring-0`;
const border = `border-[#ADADAD] border-b border-t-0 border-t-0 border-l-0 border-r-0`;
const focus = `focus:border-[#FEDA00] ring-0`;
const active = `active:border-[#FEDA00] ring-0`;

export const NativeSelect: FC<INativeSelectProps> = ({
  options,
  defaultValue = "",
  placeholder = "",
  ...rest
}) => (
  <select
    {...rest}
    className={`${defaults} ${border} ${focus} ${active} relative cursor-pointer focus-visible:ring-0`}
  >
    <option disabled value={defaultValue}>
      {placeholder}
    </option>
    {options.map((element, index) => (
      <option key={index}>{element}</option>
    ))}
  </select>
);
