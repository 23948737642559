import { createBrowserRouter } from "react-router-dom";
import { ProfilePage } from "../pages/ProfilePage/ProfilePage";
import { ActionsPage } from "../pages/ActionsPage/ActionsPage";
import { SelectPage } from "../pages/SelectPage/SelectPage";
import { DragNDropPage } from "../pages/DragNDropPage/DragNDropPage";
import { ProtectedRoute } from "./ProtectedRoute";
import { LoginPage } from "../pages/LoginPage/LoginPage";
import { RegistrationPage } from "../pages/RegistrationPage/RegistrationPage";
import { ROUTES_ENUM } from "./type";
import { Layout } from "../components/Layout/Layout";
import { SearchResultsPage } from "../pages/SearchResultsPage/SearchResultsPage";

export const ROUTER = createBrowserRouter([
  {
    path: ROUTES_ENUM.LOGIN,
    element: <LoginPage />,
  },
  {
    path: ROUTES_ENUM.REGISTRATION,
    element: <RegistrationPage />,
  },
  {
    path: ROUTES_ENUM.HOME,
    element: <ProtectedRoute page={<ProfilePage />} />,
  },
  {
    path: ROUTES_ENUM.ACTIONS,
    element: <ProtectedRoute page={<ActionsPage />} />,
  },
  {
    path: ROUTES_ENUM.DRAGNDROP,
    element: <ProtectedRoute page={<DragNDropPage />} />,
  },
  {
    path: ROUTES_ENUM.SELECT,
    element: <ProtectedRoute page={<SelectPage />} />,
  },
  {
    path: ROUTES_ENUM.SEARCH_RESULTS,
    element: <ProtectedRoute page={<SearchResultsPage />} />,
  },
  {
    path: "*",
    element: (
      <Layout>
        <h1>Page was not found!</h1>
      </Layout>
    ),
  },
]);
