import { FC } from "react";
import { IDraggableElementProps } from "./type";

export const DraggableElement: FC<IDraggableElementProps> = ({
  textContent,
  id,
  className,
  ...props
}) => {
  return (
    <span
      draggable
      className={`px-[15px] py-[7px] bg-sky-100 rounded hover:bg-sky-300 active:bg-sky-200 cursor-pointer ${
        className ?? ""
      }`}
      title='Draggable element'
      id={id || textContent} // Unique identifier for test instead of "id" property
      {...props}
    >
      {textContent}
    </span>
  );
};
