/**
 * @param { string } dateString // 20.01.2020
 * @returns { Date }
 */

export const getDateFromString = (dateString: string): Date => {
  // It's possible to add try catch to handle other formats
  const [day, month, year] = dateString.split(".").map(Number);
  return new Date(year, month - 1, day);
};
