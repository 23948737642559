export const Copyright = () => {
  const currentYear = new Date().getFullYear();

  return (
    <div className='flex flex-row gap-5 py-6 text-xs text-zinc-500'>
      <div className='shrink basis-auto grow-0'>
        © Copyright 2007-{currentYear} Andersen Software, Inc. All Rights Reserved.
      </div>
      <div className='flex-auto max-md:max-w-full'>Privacy Policy</div>
    </div>
  );
};
