import React, { useContext } from "react";
import { Input } from "../../components/Input";
import { DatePicker } from "../../components/DatePicker/custom";
import { Form } from "../../components/Form";
import * as Yup from "yup";
import { FormikProps } from "formik";
import _ from "lodash";
import { UserContext } from "../../context/userContext";
import { useMutation } from "react-query";

import { close } from "../../common/icons";
import { Button } from "../../components/Button";
import { editAccount } from "../../api/securedQueries";

export interface ProfileEditFormValues {
  firstName?: string;
  lastName?: string;
  email?: string;
  dateOfBirth?: string;
}

interface ProfileEditContentProps {
  formProps: FormikProps<ProfileEditFormValues>;
  onClose: () => void;
}

// Regular expression to match the date format 'dd-MM-yyyy'
const dateRegex = /^(\d{2})\/(\d{2})\/(\d{4})$/;

const ValidationSchema = Yup.object().shape({
  firstName: Yup.string().min(1, "Minimum 1 character").required("Required"),
  lastName: Yup.string().min(1, "Minimum 1 character").required("Required"),
  email: Yup.string().email("Invalid email address").required("Required"),
  dateOfBirth: Yup.string()
    .required("Required")
    .matches(dateRegex, "Date of birth must be in the format dd/MM/yyyy")
    .test("isValidDate", "Invalid date", (value) => {
      if (!value) return false;
      const [day, month, year] = value.split("/").map(Number);
      const date = new Date(year, month - 1, day);
      return date.getFullYear() === year && date.getMonth() === month - 1 && date.getDate() === day;
    }),
});

const ProfileEditForm = ({ formProps, onClose }: ProfileEditContentProps) => {
  const inputStyle = "border-gray-500 border-b outline-none h-9 text-sm";

  return (
    <div className='relative justify-center flex flex-col items-start p-16 bg-white max-md:px-5 w-full lg:w-[1050px]'>
      <img
        alt='Close'
        src={close}
        onClick={onClose}
        className='absolute top-10 right-10 mt-[-8px] mr-[-8px] cursor-pointer w-5 h-5'
      />
      <h1 className='text-4xl font-light leading-10 text-black max-md:max-w-full'>
        Edit personal information
      </h1>
      <p className='mt-5 text-lg leading-7 text-black max-md:max-w-full'>
        Please, provide your personal information in English.
      </p>
      <section className='self-stretch mt-10 max-md:max-w-full'>
        <div className='flex gap-5 max-md:flex-col max-md:gap-0'>
          <div className='flex flex-col w-6/12 max-md:ml-0 max-md:w-full'>
            <div className='flex flex-col grow text-xs text-neutral-400 max-md:mt-10 max-md:max-w-full md:mb-5 mb-0'>
              <Input
                type='text'
                label='First name'
                name={"firstName"}
                placeholder='First Name'
                onChange={formProps.handleChange}
                onBlur={formProps.handleBlur}
                value={formProps.values.firstName}
                error={(formProps.touched.firstName && formProps.errors.firstName) || undefined}
                customClassName={inputStyle}
              />
            </div>
            <div className='flex flex-col grow text-xs text-neutral-400 max-md:mt-10 max-md:max-w-full'>
              <Input
                type='text'
                label='Last name'
                name='lastName'
                placeholder='Last Name'
                onChange={formProps.handleChange}
                onBlur={formProps.handleBlur}
                value={formProps.values.lastName}
                error={(formProps.touched.lastName && formProps.errors.lastName) || undefined}
                customClassName={inputStyle}
              />
            </div>
          </div>
          <div className='flex flex-col ml-5 w-6/12 max-md:ml-0 max-md:w-full'>
            <div className='flex flex-col grow text-xs text-neutral-400 max-md:mt-10 max-md:max-w-full md:mb-5 mb-0'>
              <Input
                type='text'
                label='E-mail'
                name='email'
                placeholder='E-mail'
                onChange={formProps.handleChange}
                onBlur={formProps.handleBlur}
                value={formProps.values.email}
                error={(formProps.touched.email && formProps.errors.email) || undefined}
                customClassName={inputStyle}
              />
            </div>
            <div className='relative flex flex-col grow text-xs text-neutral-400 max-md:mt-10 max-md:max-w-full'>
              <DatePicker
                value={formProps.values.dateOfBirth}
                setFieldValue={formProps.setFieldValue}
                onChange={formProps.handleChange}
                errors={formProps.errors}
              />
            </div>
          </div>
        </div>
      </section>
      <nav className='flex gap-5 justify-between mt-10 text-sm font-medium leading-6 text-center text-black whitespace-nowrap'>
        <Button
          id='ProfileEditCancelButton' // Unique identifier for tests
          text='Cancel'
          type='button'
          classArray={[
            "flex flex-col justify-center px-11 py-3 border-2 border-yellow-400 border-solid max-md:px-5",
          ]}
          onClick={onClose}
        />
        <Button
          id='ProfileEditSubmitButton' // Unique identifier for tests
          disabled={formProps.isSubmitting || !_.isEmpty(formProps.errors)}
          text='Save'
          type='submit'
          classArray={[
            "flex flex-col justify-center px-12 py-3 bg-yellow-400 max-md:px-5",
            (formProps.isSubmitting || !_.isEmpty(formProps.errors)) && "opacity-60",
          ]}
        />
      </nav>
    </div>
  );
};

interface ProfileEditDialogProps {
  values: ProfileEditFormValues;
  onClose: () => void;
}

export const ProfileEditDialog = ({ values, onClose }: ProfileEditDialogProps) => {
  const userContext = useContext(UserContext);
  const mutationUpdate = useMutation(editAccount);

  const onHandleSubmit = async (values: ProfileEditFormValues) => {
    try {
      const newData = {
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        dateOfBirth: values.dateOfBirth,
      };

      await mutationUpdate.mutateAsync(newData);
      userContext?.getAccount();
      onClose();
    } catch (e) {
      console.error("onSubmit update", e);
    }
  };

  return (
    <div className='fixed inset-0 flex justify-center items-center bg-[rgba(2,13,28,0.7)] z-50'>
      <Form<ProfileEditFormValues>
        validationSchema={ValidationSchema}
        onSubmit={onHandleSubmit}
        initialValues={{
          ...values,
        }}
      >
        {(formProps) => <ProfileEditForm formProps={formProps} onClose={onClose} />}
      </Form>
    </div>
  );
};
