import { Typography } from "../../components/Typography/Typography";
import { SearchResultItem } from "../../components/SearchResultItem/SearchResultItem";
import { formatDateWithMonthName } from "../../utils/formatDateWithMonthName";
import { getDateFromString } from "../../utils/getDateFromString";
import { useContext } from "react";
import { SearchContext } from "../../context/searchContext";
import { ICourse } from "../../context/type";
import { useNavigate } from "react-router-dom";
import { ROUTES_ENUM } from "../../router/type";

export const SearchResultsPage = () => {
  const navigate = useNavigate();

  const { coursesContextValue, setChosenCourseContextValue } = useContext(SearchContext);

  const onCourseClick = (chosenCourse: ICourse) => {
    setChosenCourseContextValue(chosenCourse);
    navigate(ROUTES_ENUM.ACTIONS);
  };

  const isCoursesFound = !!coursesContextValue.length;

  const groupedCourses = coursesContextValue.reduce((acc: { [key: string]: ICourse[] }, course) => {
    if (!acc[course.type]) {
      acc[course.type] = [];
    }
    acc[course.type].push(course);
    return acc;
  }, {});

  return (
    <section>
      <Typography renderAs='h1' className='mb-[35px]'>
        Search results
      </Typography>
      {!isCoursesFound && (
        <Typography renderAs='h2' className='mb-[35px]'>
          Unfortunately, we did not find any courses matching your chosen criteria.
        </Typography>
      )}
      {isCoursesFound && (
        <div className='flex flex-col gap-5'>
          {Object.keys(groupedCourses).map((type) => (
            <div className='flex flex-col gap-5' key={type}>
              <Typography renderAs='h2' className='mt-5'>
                {type}
              </Typography>
              {groupedCourses[type].map((element, index) => (
                <SearchResultItem
                  onClick={() => onCourseClick(element)}
                  key={index}
                  className='flex cursor-pointer hover:bg-[#EFEFF0]'
                >
                  <div className='flex-1'>{element.name}</div>
                  <div className='flex flex-1'>
                    <div className='text-gray-500 flex-1 font-thin'>
                      {formatDateWithMonthName(getDateFromString(element.startDate))}
                    </div>
                    <div className='flex-1'>{element.type}</div>
                    <div className='flex flex-1 justify-end'>
                      <span className='text-black font-semibold border-b border-yellow-400'>
                        View
                      </span>
                    </div>
                  </div>
                </SearchResultItem>
              ))}
            </div>
          ))}
        </div>
      )}
    </section>
  );
};
